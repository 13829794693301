import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Typography variant="h1" gutterBottom>
        About
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to my website!
      </Typography>
    </div>
  );
};

export default About;