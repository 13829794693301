import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem, Avatar, Divider, Box, IconButton, Drawer, Typography, List, ListItem, ListItemButton } from '@mui/material';
import { Language, Logout, Menu as MenuIcon } from '@mui/icons-material';
import styled from 'styled-components';
import logo from "../assets/confirmare-logo.png"; // with import
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import vi1 from "../assets/conf_vi_1.png"; // with import
import vi2 from "../assets/conf_vi_2.png"; // with import
import vi3 from "../assets/conf_vi_3.png"; // with import
import vi4 from "../assets/conf_vi_4.png"; // with import


const drawerWidth = 240;

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    console.log('Menu', mobileOpen);
    setMobileOpen((prevState) => !prevState);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // handleClose();
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <IconHome src={logo} style={{ marginTop: '1rem' }} />

      <Divider />
      <List>
        <ListItem >
          <ListItemButton >
            <StyledButton component={NavLink} to="/experience" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
              {t('experience1')}
              <br />
              {t('experience2')}

            </StyledButton>
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton sx={{ textAlign: 'center' }}>
            <StyledButton component={NavLink} to="/services" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
              {t('services')}
            </StyledButton>
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton sx={{ textAlign: 'center' }}>

            <StyledButton component={NavLink} to="/mi-confirmare" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
              {t('confirmare')}
            </StyledButton>
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton sx={{ textAlign: 'center' }}>
            <StyledButton component={NavLink} to="/contact" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
              {t('contact')}

            </StyledButton>
          </ListItemButton>
        </ListItem>

        <Box
          sx={{ display: "flex", marginLeft: "37px" }}
        >
          <Button variant="text" onClick={() => changeLanguage('es')}>
            Español
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button variant="text" onClick={() => changeLanguage('en')}>
            Inglés
          </Button>
        </Box>

        {/* <StyledLanguageIcon onClick={handleClick} /> */}
      </List>
    </Box>
  );

  return (
    <React.Fragment>

      <StyledAppBar position="fixed" elevation={0}>


        <StyledToolbar sx={{ display: { md: 'none' } }} >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
            color="black"
          >
            <MenuIcon />
          </IconButton>
          <StyledButton component={NavLink} to="/" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            <IconHome src={logo} />

          </StyledButton>

        </StyledToolbar>


        <StyledToolbar sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <StyledButton component={NavLink} to="/" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            <IconHome src={logo} />

          </StyledButton>
          <StyledButton component={NavLink} to="/experience" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            {t('experience1')}
            <br />
            {t('experience2')}
            <ImgVin style={{ bottom: '5px' }}
              src={vi1} />
          </StyledButton>
          <StyledButton component={NavLink} to="/services" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            {t('services')}
            <ImgService
              src={vi2} />
          </StyledButton>
          <StyledButton component={NavLink} to="/mi-confirmare" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            {t('confirmare')}
            <ImgVin
              src={vi3} />
          </StyledButton>
          <StyledButton component={NavLink} to="/contact" className={(navData) => (navData.isActive ? "active" : 'none')} color="inherit">
            {t('contact')}
            <ImgVin
              src={vi4} />
          </StyledButton>

          <Box
            sx={{ display: "flex" }}
          >
            <Button variant="text" onClick={() => changeLanguage('es')}>
              Español
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button variant="text" onClick={() => changeLanguage('en')}>
              Inglés
            </Button>
          </Box>

          {/* <StyledLanguageIcon onClick={handleClick} /> */}
        </StyledToolbar>


      </StyledAppBar>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => changeLanguage('es')}>
          Español
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          Ingles
        </MenuItem>
        <Divider />

      </Menu>

      <Box component="nav">
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </React.Fragment>

  );
};

const StyledAppBar = styled(AppBar)`
  background-color: #ffffffe9 !important;
  height: 5rem;
  padding: 0 3rem;
  
  
`;

const StyledToolbar = styled(Toolbar)`
  height:100%;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  position: relative;
  text-transform: none;
  color: #000 !important;
  font-family: 'Montserrat', sans-serif !important; 
  font-size: 0.8rem !important;
  height: 100%;

  &.active {
    background-color: transparent;
    color: #E89472 !important;

    & img {
      display: unset;
    }
  }

  :hover{
    background-color: none;
  }
  span{
    color: #000;

  }
`

const ImgVin = styled.img`
  position: absolute;
  display:none;
  bottom:9px;
`;

const ImgService = styled.img`
  position: absolute;
  display:none;
  bottom:9px;
  left:-46px;
`;


const StyledLanguageIcon = styled(Language)`
  cursor: pointer;
  color: #E89472;

`;

const IconHome = styled.img`
  cursor: pointer;
  width: 3rem;
`

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
