import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
//import { Route, Switch, Router } from 'react-router';

import { useTranslation } from 'react-i18next';
import Navbar from './components/navbar.jsx';
import Home from './pages/home.jsx';
import Contact from './pages/contact.jsx';
import Experience from './pages/expierence.jsx';
import Services from './pages/services.jsx';
import MiConfirmare from './pages/mi-confirmare.jsx';
import About from './pages/about.jsx';
import PrivacyPolicy from './pages/privacy-policy.jsx';
import TermsAndConditions from './pages/terms.jsx';
import WorkPolicy from './pages/work-policy.jsx';
import { useLayoutEffect } from 'react';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const App = () => {
  return (
    <Router>
      <Wrapper>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/services" element={<Services />} />
          <Route path="/mi-confirmare" element={<MiConfirmare />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/work-policy" element={<WorkPolicy />} />
        </Routes>
      </Wrapper>

    </Router>
  );
};

export default App;
