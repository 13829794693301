
import React from 'react';
import styled from 'styled-components';
import footerBg from '../assets/conf-bg-footer.png'
import { Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';

import facebook from "../assets/confirmare-facebook.png"; // with import
import ig from "../assets/conjfirmare-ig.png"; // with import
import whatsapp from "../assets/confirmare-whatsapp.png";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";


const Footer = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    function handleClick(route) {
        navigate(route);
    }

    return (
        <FooterContainer>

            <Section >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}  textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant="p"  >
                            {t('line1')}
                        </Typography >
                        <br />
                        <Typography variant="p"  >
                            {t('line2')}
                        </Typography >
                        <br />
                        <Typography variant="p"  >
                            {t('line3')}
                        </Typography >
                        <br />
                        <Typography variant="p"  >
                            {t('line4')}
                        </Typography >
                    </Grid>
                    <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                        <Box textAlign={{ xs: 'center', md: 'right' }}>
                            <Typography variant="p" style={{ verticalAlign: 'super' }} >
                                {t('followUs')}
                            </Typography >
                            <a href='https://www.facebook.com/confirmare' target="_blank">
                                <img
                                    src={facebook}
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "0 0.5rem"
                                    }}
                                /></a>


                            <a href='https://www.instagram.com/confirmare.mx/' target="_blank">
                                <img
                                    src={ig}
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "0 0.5rem"
                                    }}
                                />
                            </a>
                        </Box>

                        <br />

                        <Box textAlign={{ xs: 'center', md: 'right' }}>
                            <a href='https://wa.me/+523396880318' target="_blank">

                                <img
                                    src={whatsapp}
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "0px 0.5rem"
                                    }}
                                />
                            </a>
                            <Typography variant="p"  style={{ verticalAlign: 'super' }}>
                                {t('whatsappNumber')}
                            </Typography >
                        </Box>


                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }} md={4} style={{ cursor: 'pointer'  }} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant="p" onClick={() => handleClick('/terms-conditions')}>
                            {t('termsAndConditions')}
                        </Typography >
                        <br />
                        <Typography variant="p" onClick={() => handleClick('/privacy-policy')}>
                            {t('privacyPolicy')}
                        </Typography >
                        <br />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center", textAlign: "center" , cursor: 'pointer'  }} >
                        <p
                            style={{ textAlign: "center", fontSize: "12px", fontWeight: "600", marginBottom: "1rem " }}
                        >
                            {t('copyright')}
                        </p>
                    </Grid>
                </Grid>
            </Section>

        </FooterContainer>

    );
};

export default Footer;

const Section = styled.section`

  padding-top: 1rem;
  
  @media (min-width: 768px) {
    padding-left: 5rem;
    padding-right: 5rem;
        padding-top: 3rem;
      }

  
`;

const FooterContainer = styled.footer`
    background-image: url(${footerBg});       
        background-repeat: repeat;
        background-size: contain;
       
`;

const DividerContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: 3rem 0;

`;

const DividerText = styled.div`
   margin: 0 1rem;
   color: #90C1BD;
`;

