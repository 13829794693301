import React from 'react';
import ExpInfo from '../components/exp'
import Footer from '../components/footer'
import styled from 'styled-components';
import heroImg from '../assets/con_serv_img.png'
import servIcon from '../assets/conf_serv_icon.png'
import servIcon1 from '../assets/conf_serv_icon_1.png'
import servIcon2 from '../assets/conf_serv_icon_2.png'
import servIcon3 from '../assets/conf_serv_icon_3.png'
import servIcon4 from '../assets/conf_serv_icon_4.png'
import servIcon5 from '../assets/conf_serv_icon_5.png'
import servIcon6 from '../assets/conf_serv_icon_6.png'
import servIcon7 from '../assets/conf_serv_icon_7.png'
import servIcon8 from '../assets/conf_serv_icon_8.png'
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';



const Services = () => {
    const { t } = useTranslation();

    return (
        <div style={{ marginTop: "5rem" }}>

            <Section >
                <GridWrapper container spacing={8} padding={5}>
                    <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                        <ImgServ src={heroImg} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ExpInfo textDirection="left" icon={servIcon} title={t('confirmareCallCenter')} description={t('confirmareCallCenterDesc')} />
                    </Grid>
                </GridWrapper>
                <ServiceDescContainer style={{ fontFamily: 'Montserrat' }}>
                    {t('confirmareCallCenterContainer')}
                </ServiceDescContainer>
            </Section>
            <Section >
                <Grid container spacing={2} columns={16} padding={5} justifyContent="space-between"
                >
                    <CustomGridItem item xs={7} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={servIcon1} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('llamadasNacionalesEInternacionales')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon2} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('invitacionesDigitales')}
                        </Typography >

                    </CustomGridItem><CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon3} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('mailingYMensajería')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon4} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('intentosIlimitados')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon5} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('scriptPersonalizado')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon6} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('miConfirmareEnLinea')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon7} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('seguimientoPuntual')}
                        </Typography >

                    </CustomGridItem>
                    <CustomGridItem item xs={7} md={2} spacing={3}>
                        <IconWrapper  >
                            <IconService src={servIcon8} />
                        </IconWrapper >
                        <Typography style={{ fontFamily: 'Montserrat-medium' }}>
                            {t('atenciónConCortesía')}
                        </Typography >

                    </CustomGridItem>
                </Grid>
                <Typography marginBottom={8} paddingX={{ xs: 2, md: 20 }} textAlign={{ xs: 'center', md: 'left' }} style={{ fontFamily: 'Montserrat-light', fontSize: '14px' }}>
                    {t('serviceWarningLabel')}
                </Typography >

            </Section>
            <Footer />


        </div>);


}

export default Services;


const Section = styled.section`

  padding: 0px;
  
  @media (min-width: 768px) {
        padding: 0 4rem;
      }

  
`;



const GridWrapper = styled(Grid)`
  margin: 2rem;
`;

const CustomGridItem = styled(Grid)`
text-align: center;
  p,h4{
    color:#DF7C7B;
    font-size: 12px;
  }
`;



const ImgServ = styled.img`
  width: 100%;
  max-width: 500px;
  
  
  
`;


const ServiceDescContainer = styled.div`
  padding: 0.5rem;
  background-color: #D8ECE6;
  width: 80%;
  font-size: 1rem;
  margin:auto;
  text-align: center;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;


const IconService = styled.img`
  width: 60px;
`;

const IconWrapper = styled.div`
    width: 100px;
    height: 100px;
  display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
`;
