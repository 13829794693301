import React from 'react';
import styled from 'styled-components';



const WorkPolicy = () => {
    return (
        <Section style={{ marginTop: "10rem", padding: "0 50px" }}>

                <div>
                  
                </div>
                <p class="c2 c18"><span class="c0"></span></p>
                <p class="c25"><span class="c27">P O L &Iacute; T I C A S &nbsp; D E &nbsp; T R A B A J O </span></p>
                <p class="c31"><span class="c29"></span></p>
                <ul class="c3 lst-kix_list_14-0 start">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;proporciona los servicios de mensajer&iacute;a y </span><span class="c11">call
                            center</span><span class="c0">&nbsp;para el env&iacute;o de invitaciones, la confirmaci&oacute;n de
                                invitaciones y/o invitados con estricto apego al C&oacute;digo de Etiqueta en el de
                                Confirmare</span><span class="c1">MR</span><span class="c0">.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;se reserva el derecho a rechazar los servicios que atenten en contra de sus principios,
                        pol&iacute;ticas de trabajo, valores y misi&oacute;n.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;, en caso de que solo contraten </span><span class="c11">call center</span><span
                            class="c0">&nbsp;para confirmar, requiere que sus clientes tengan todas sus invitaciones entregadas y/o
                            enviadas previo a comenzar el servicio.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></li>
                </ul>
                <p class="c30"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Los servicios de mensajer&iacute;a y </span><span
                        class="c11">call center</span><span class="c0">&nbsp;se lleva a cabo a partir de las 09:00hrs y hasta
                            las 19:00hrs de lunes a viernes. De las 09:00hrs a las 13:00hrs los s&aacute;bados, y exclusivamente
                            para servicios express, de las 11:00hrs a las 13:00hrs los domingos. &nbsp;Confirmare</span><span
                                class="c1">MR</span><span class="c0">&nbsp;no realizar&aacute; intentos o enviar&aacute; mensaje fuera
                                    de este horario.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;aceptar&aacute; &uacute;nicamente los registros completos por invitaci&oacute;n y/o
                        invitado. &nbsp;Un registro completo deber&aacute; contener el nombre y apellidos, la cantidad de
                        boletos y al menos dos tel&eacute;fonos de contacto, o en su defecto un tel&eacute;fono y un correo
                        electr&oacute;nico.</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1 start" start="1">
                    <li class="c9 li-bullet-0"><span class="c0">Nombre: </span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Al menos el primer nombre y el primer apellido.</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Cuando el protocolo lo amerite, deber&aacute; incluir tambi&eacute;n
                        el cargo que desempe&ntilde;a.</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1" start="2">
                    <li class="c9 li-bullet-0"><span class="c0">N&uacute;meros de contacto:</span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Proporcionar a 10 d&iacute;gitos los n&uacute;mero locales y
                        nacionales, fijos o celulares m&aacute;s el c&oacute;digo del pa&iacute;s (+52 para
                        M&eacute;xico)</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Para llamadas internacionales, indicar el c&oacute;digo
                        internacional m&aacute;s los d&iacute;gitos que van desde 7 n&uacute;meros. Encuentra el c&oacute;digo
                        de tu pa&iacute;s en </span><span class="c26"><a class="c14"
                            href="https://www.google.com/url?q=https://countrycode.org/&amp;sa=D&amp;source=editors&amp;ust=1685847147074372&amp;usg=AOvVaw0BLb3CGeSesAQ2U7Es8Ci9">https://countrycode.org/</a></span>
                    </li>
                    <li class="c8 li-bullet-0"><span class="c0">Ubicar el n&uacute;mero en la columna que le corresponda en la
                        &ldquo;Lista de invitaciones, registros y/o invitados&rdquo;, es decir, si el tel&eacute;fono es fijo o
                        celular</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1" start="3">
                    <li class="c9 li-bullet-0"><span class="c0">Correo electr&oacute;nico:</span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Si el evento es de categor&iacute;a empresarial se espera el correo
                        electr&oacute;nico de trabajo</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Si el evento es de categor&iacute;a social, correo
                        electr&oacute;nico indistinto</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_15-0 start">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;, para el servicio de invitaciones digitales, aceptar&aacute; &uacute;nicamente
                        archivos .PDF con un tama&ntilde;o m&aacute;ximo de 5MB.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">En el caso de que se presente alguna modificaci&oacute;n en la
                        &ldquo;Invitaci&oacute;n digital, lista de invitaciones, registros y/o invitados&rdquo;, el cliente
                        deber&aacute; notificar por escrito a Confirmare</span><span class="c1">MR</span><span
                            class="c0">.</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1 start" start="1">
                    <li class="c9 li-bullet-0"><span class="c0">Modificaciones posibles:</span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Cambio de datos de contacto</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Cancelaci&oacute;n de la invitaci&oacute;n</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Agregar un nuevo registro de invitado</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Gesti&oacute;n de intentos:</span></li>
                </ul>
                <p class="c6 c21"><span class="c20">Telef&oacute;nicos:</span></p>
                <ol class="c3 lst-kix_list_14-1" start="2">
                    <li class="c9 li-bullet-0"><span class="c0">La cantidad de intentos (telef&oacute;nicos y electr&oacute;nicos)
                        que llevar&aacute; a cabo Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;a una
                            invitaci&oacute;n y/o registro con tel&eacute;fonos locales, nacionales y celulares ser&aacute;
                            ilimitada.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">La cantidad de intentos que llevar&aacute; a cabo
                        Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;a una invitaci&oacute;n con
                            n&uacute;mero de tel&eacute;fono internacional se limitar&aacute; a 3 (tres). En caso de requerir
                            m&aacute;s de 3 (tres) intentos, esto generar&aacute; un cargo adicional de $22.00 (Veintidos Pesos
                            00/100 M.N.) por intento extra.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">El env&iacute;o de invitaciones digitales se realizar&aacute; a
                        trav&eacute;s de </span><span class="c11">WhatsApp</span><span class="c0">&nbsp;en una sola (1)
                            campa&ntilde;a con un registro y/o tel&eacute;fono por invitaci&oacute;n. Los reenv&iacute;os de
                            invitaciones a tel&eacute;fonos y/o registros corregidos o nuevos, generan un cargo adicional de $18.50
                            (Dieciocho Pesos 00/100 M.N.) por reenv&iacute;o extra.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">A partir de la fecha de inicio de llamadas y hasta un d&iacute;a
                        antes de la fecha de entrega del reporte final, Confirmare</span><span class="c1">MR</span><span
                            class="c0">&nbsp;realizar&aacute; al menos 1 intento telef&oacute;nico cada tercer d&iacute;a por
                            registro (invitaci&oacute;n).</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento telef&oacute;nico, el n&uacute;mero de contacto
                        tiene </span><span class="c11">error</span><span class="c0">, este registro se cancelar&aacute;.
                            &nbsp;Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;notificar&aacute; al cliente y
                                &eacute;ste decidir&aacute; si entrega nuevos datos o descarta esa confirmaci&oacute;n. </span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Errores comunes en n&uacute;mero de contacto:</span></li>
                </ul>
                <ul class="c3 lst-kix_list_14-3 start">
                    <li class="c5 li-bullet-0"><span class="c0">No existe</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">No es v&aacute;lido</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">No est&aacute; asignado</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Fuera de servicio</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Temporalmente suspendido</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">No disponible</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Desconectado</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Faltan d&iacute;gitos</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Sobran d&iacute;gitos</span></li>
                    <li class="c5 li-bullet-0"><span class="c0">Fuera del &aacute;rea de servicio</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1" start="7">
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento se determina que el n&uacute;mero de
                        tel&eacute;fono </span><span class="c11">no corresponde a la persona registrada</span><span
                            class="c0">&nbsp;para la confirmaci&oacute;n, el registro se rechazar&aacute; al cliente y se
                            tomar&aacute; en cuenta como registro contactado para efectos del estado de cuenta &nbsp;por la tarifa
                            seg&uacute;n el convenio de trabajo.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento </span><span class="c11">no hubo
                        contacto</span><span class="c0">&nbsp;con un buz&oacute;n de voz, un mensaje de texto (SMS - WhatsApp)
                            un tercero o el invitado, se podr&aacute; llevar a cabo 1 intento adicional en el mismo
                            d&iacute;a.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento hubo </span><span class="c11">contacto con un
                        buz&oacute;n de voz, se dej&oacute; mensaje </span><span class="c0">de texto (SMS - WhatsApp) o con un
                            tercero, Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;contactar&aacute; de nuevo en
                                3 (tres) d&iacute;as h&aacute;biles.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento hubo contacto con el invitado &oacute; un tercero,
                        y &eacute;ste </span><span class="c11">solicita llamemos despu&eacute;s</span><span class="c0">,
                            Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;contactar&aacute; de nuevo en la fecha
                                u horario solicitado.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento </span><span class="c11">un tercero confirma o
                        cancela</span><span class="c0">, Confirmare</span><span class="c1">MR</span><span
                            class="c0">&nbsp;tomar&aacute; esa respuesta como buena y modificar&aacute; el status del registro a
                            confirmado o cancelado.</span></li>
                    <li class="c9 li-bullet-0"><span class="c0">Si en el intento telef&oacute;nico, por mensaje de texto o por
                        correo </span><span class="c11">el invitado confirma o cancela</span><span class="c0">,
                            Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;tomar&aacute; esa respuesta como buena
                                y modificar&aacute; el status del registro a confirmado o cancelado.</span></li>
                </ol>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;iniciar&aacute; el o los servicios de acuerdo a las fechas estipuladas en el Calendario
                        de Trabajo.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;realizar&aacute; las </span><span class="c11">llamadas, mensajes y/o correos
                        </span><span class="c0">con calidad, apegado al C&oacute;digo de Etiqueta en el Tel&eacute;fono de
                            Confirmare</span><span class="c1">MR</span><span class="c0">, y escrupuloso cumplimiento del
                                gui&oacute;n de llamada del evento.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Cuando un invitado presente una </span><span
                        class="c11">inquietud no cubierta en el gui&oacute;n de llamada</span><span class="c0">, ni en la
                            informaci&oacute;n proporcionada por el cliente, Confirmare</span><span class="c1">MR</span><span
                                class="c0">&nbsp;notificar&aacute; al cliente.</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1 start" start="1">
                    <li class="c9 li-bullet-0"><span class="c0">El cliente decidir&aacute; si, atiende personalmente la inquietud
                        del invitado o proporciona informaci&oacute;n adicional a Confirmare</span><span
                            class="c1">MR</span><span class="c0">. &nbsp; </span></li>
                </ol>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Cada llamada y/o campa&ntilde;a posterior a la fecha de cierre
                        y/o </span><span class="c11">invitados con status: confirmado si asiste, confirmado no asiste, no sabe
                            todav&iacute;a o no se pudo contactar</span><span class="c0">&nbsp;tendr&aacute; un costo adicional de
                                $22.00 (Veintid&oacute;s Pesos 00/100 M.N.).</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;se reserva el derecho a </span><span class="c11">suprimir llamadas, mensajes, intentos
                            o correos a un invitado</span><span class="c0">&nbsp;si &eacute;ste muestra actitud negativa.
                                &nbsp;Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;comunicar&aacute; al cliente el
                                    rechazo del servicio a ese invitado y su respectivo motivo.</span></li>
                </ul>
                <ol class="c3 lst-kix_list_14-1 start" start="1">
                    <li class="c9 li-bullet-0"><span class="c0">Actitudes negativas:</span></li>
                </ol>
                <ul class="c3 lst-kix_list_14-2 start">
                    <li class="c8 li-bullet-0"><span class="c0">Enojo</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Molestia</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Falta de respeto</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Rechazo</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Lenguaje altisonante</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Reclamo</span></li>
                    <li class="c8 li-bullet-0"><span class="c0">Desconfianza</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Si en el intento se determina </span><span class="c11">suprimir
                        la llamada, mensajes o correos electr&oacute;nicos a un invitado</span><span class="c0">&nbsp;se
                            tomar&aacute; en cuenta como registro contactado para efectos del estado de cuenta por la tarifa por la
                            tarifa seg&uacute;n el convenio de trabajo.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;se reserva el derecho de </span><span class="c11">suprimir llamada, menajes o correos a
                            un invitado</span><span class="c0">&nbsp;si no es titular del n&uacute;mero telef&oacute;nico
                                proporcionado por el cliente.</span></li>
                </ul>
                <p class="c2 c6"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;brindar&aacute; acceso a Mi Confirmare</span><span class="c1">MR</span><span
                            class="c0">&nbsp;v&iacute;a internet a sus clientes para consultas y revisi&oacute;n de avances en la
                            confirmaci&oacute;n de la lista de invitaciones, registrosy/o invitados.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;otorgar&aacute; a sus clientes una cuenta de acceso a &ldquo;Mi Confirmare&rdquo; que
                        consta de un nombre de usuario y una contrase&ntilde;a.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c10 c6 li-bullet-0"><span class="c0">Para clientes sin servicio &ldquo;Mi Confirmare&rdquo;,
                        Confirmare</span><span class="c1">MR</span><span class="c0">&nbsp;enviar&aacute; un </span><span
                            class="c11">avance de confirmaciones</span><span class="c0">&nbsp;al cliente de acuerdo la periodicidad
                                establecida en el formato de registro de evento.</span></li>
                </ul>
                <p class="c2"><span class="c0"></span></p>
                <ul class="c3 lst-kix_list_14-0">
                    <li class="c6 c10 li-bullet-0"><span class="c0">Confirmare</span><span class="c1">MR</span><span
                        class="c0">&nbsp;brindar&aacute; </span><span class="c11">soporte </span><span class="c0">a aquellos
                            clientes que utilicen &ldquo;Mi Confirmare&rdquo; apegado a lo declarado en Pol&iacute;ticas de Soporte
                            &nbsp;&ldquo;Mi Confirmare&rdquo; publicadas dentro del portal.</span></li>
                </ul><a id="t.65aaff097b720e46597af9f380027fa30f2a5bd0"></a><a id="t.0"></a>
                <table class="c17">
                    <tr class="c23">
                        <td class="c7" colspan="1" rowspan="1">
                            <p class="c13"><span class="c0"></span></p>
                        </td>
                        <td class="c7" colspan="1" rowspan="1">
                            <p class="c13"><span class="c0"></span></p>
                        </td>
                    </tr>
                </table>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <p class="c2"><span class="c0"></span></p>
                <div>
                    <p class="c13"><span class="c12"></span></p>
                    <p class="c13"><span class="c22"></span></p>
                    <p class="c25"><span class="c16">Copyright &copy; 2022 Confirmare</span><span class="c4">MR</span><span
                        class="c16">. Todos los derechos reservados.</span></p>
                    <p class="c19"><span class="c12"></span></p>
                </div>
        </Section>
    );

}

export default WorkPolicy;

const Section = styled.section`

`;