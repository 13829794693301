import React from 'react';
import Footer from '../components/footer'



const TermsAdnConditions = () => {
    return (
        <>
            <section style={{ marginTop: "10rem",marginBottom: "3rem", padding: "0 50px" }}>


                <body class="c21 doc-content">
                    <div>

                    </div>
                    <p class="c1 c12 c22"><span class="c0"></span></p>
                    <p class="c16"><span class="c2">T &Eacute; R M I N O S &nbsp; Y &nbsp; C O N D I C I O N E S</span></p>
                    <p class="c16 c17"><span class="c0"></span></p>
                    <p class="c17 c19"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">PRIMERA. - OBJETO. - </span><span class="c4">Sujeto a los t&eacute;rminos y
                        condiciones establecidos </span><span class="c2">CONFIRMARE</span><span
                            class="c4">&nbsp;proporcionar&aacute; el o sus servicios a </span><span class="c2">EL CLIENTE</span><span
                                class="c4">, por lo que </span><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;se obliga a pagar a
                        </span><span class="c2">CONFIRMARE </span><span class="c4">la contraprestaci&oacute;n por el o los servicios
                            descritos en la </span><span class="c2">COTIZACI&Oacute;N</span><span class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">El o los servicios que </span><span class="c2">CONFIRMARE </span><span
                        class="c4">proporciona a </span><span class="c2">EL CLIENTE</span><span class="c0">&nbsp;ser&aacute;
                            comprendido de la siguiente manera:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6 c12"><span class="c4">a.- Servicio de env&iacute;o de invitaciones digitales. - Disponer de los equipos
                        y personal necesarios para el env&iacute;o de mensajes, campa&ntilde;as y/o mails con la invitaci&oacute;n
                        digital (PDF) a los invitados al evento organizado por </span><span class="c2">EL CLIENTE</span><span
                            class="c4">, evento que est&aacute; descrito en la </span><span class="c2">COTIZACI&Oacute;N</span><span
                                class="c0">, en el cual se estipula los alcances, condiciones y t&eacute;rminos de dicho servicio.</span>
                    </p>
                    <p class="c1 c12"><span class="c0"></span></p>
                    <p class="c6 c12"><span class="c4">b.- Servicio de confirmaci&oacute;n de invitados. - Disponer de los equipos y
                        personal necesarios para la realizaci&oacute;n de llamadas, mensajes y/o mails a los invitados al evento
                        organizado por </span><span class="c2">EL CLIENTE</span><span class="c4">, evento que est&aacute; descrito
                            en la </span><span class="c2">COTIZACI&Oacute;N</span><span class="c0">, en el cual se estipula los
                                alcances, condiciones y t&eacute;rminos de dicho servicio.</span></p>
                    <p class="c1 c12"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c2">CONFIRMARE </span><span class="c4">proporcionar&aacute; el o los servicios antes
                        descritos a </span><span class="c2">EL CLIENTE</span><span class="c4">, &uacute;nica y exclusivamente para
                            el evento y en los d&iacute;as que se describen en el </span><span class="c2">CALENDARIO DE
                                TRABAJO</span><span class="c0">. </span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">El o los servicios que </span><span class="c2">CONFIRMARE </span><span
                        class="c4">proporciona a </span><span class="c2">EL CLIENTE </span><span class="c4">ser&aacute;n ejecutados
                            de manera profesional y con esmero de acuerdo a las condiciones establecidas en las </span><span
                                class="c2">POL&Iacute;TICAS DE TRABAJO</span><span class="c0">. </span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">SEGUNDA. - CALENDARIO DE TRABAJO. - CONFIRMARE </span><span
                        class="c4">proporcionar&aacute; el o los servicios mencionados en la Cl&aacute;usula Primera, de acuerdo a
                        los calendarios y esquemas de trabajo se&ntilde;alados en el </span><span class="c2">CALENDARIO DE
                            TRABAJO</span><span class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />

                    <p class="c6"><span class="c2">TERCERA. - CONFIDENCIALIDAD. - </span><span class="c0">Las Partes se obligan
                        mutuamente a tratar y mantener con car&aacute;cter confidencial cualquier informaci&oacute;n comercial,
                        financiera, contable, y t&eacute;cnica tal como conceptos, dise&ntilde;os, procesos, t&eacute;cnicas,
                        equipos, ideas, muestras, planes de negocios, de mercadeo y comercializaci&oacute;n, listas de precios, que
                        con motivo de la contrataci&oacute;n de nuestros servicios pudiere llegar a su respectivo poder. De misma
                        manera, las Partes reconocen que a menos que su respectiva contraparte indique lo contrario en forma
                        escrita, toda informaci&oacute;n que se intercambie entre ellas, tendr&aacute; car&aacute;cter de secreto
                        industrial, excepci&oacute;n hecha de aquella que sea parte del dominio p&uacute;blico en los
                        t&eacute;rminos de los art&iacute;culos 82, 83, 84 y 85 de la Ley de la Propiedad Industrial.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Esta Cl&aacute;usula de Confidencialidad constri&ntilde;e a </span><span
                        class="c2">EL CLIENTE</span><span class="c0">&nbsp;a pr&aacute;cticas anticompetitivas, ya que no
                            podr&aacute; divulgar ninguna informaci&oacute;n sobre la otra parte que pueda perjudicarla o darle mala
                            imagen, que por virtud de la relaci&oacute;n jur&iacute;dica que nace del presente Contrato, se entere. En
                            caso de no observar esta cl&aacute;usula, la Parte que la violente responder&aacute; por los da&ntilde;os y
                            perjuicios que causare a la otra, adem&aacute;s de rescindirse autom&aacute;ticamente del Contrato.</span>
                    </p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Por el otro lado, </span><span class="c2">CONFIRMARE</span><span
                        class="c4">&nbsp;cabalmente realizar&aacute; todas sus actividades relacionadas con el o los servicios de
                        acuerdo al </span><span class="c2">AVISO DE PRIVACIDAD</span><span class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />

                    <p class="c6"><span class="c2">CUARTA. - INFORMACI&Oacute;N DE EL CLIENTE. - &nbsp;</span><span class="c4">Para el
                        cumplimiento del o los servicios, </span><span class="c2">EL CLIENTE</span><span
                            class="c4">&nbsp;proporcionar&aacute; a </span><span class="c2">CONFIRMARE</span><span
                                class="c0">&nbsp;&ldquo;toda informaci&oacute;n necesaria para las actividades relacionadas con el evento
                            objeto del o los servicios contratados&rdquo; que comprende los siguientes:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_6-0 start" start="1">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">El domicilio y en su caso el nombre comercial del lugar en
                            que se llevar&aacute; a cabo el evento, previamente establecido en el </span><span class="c2">REGISTRO
                                DE EVENTO</span><span class="c0">.</span></li>
                    </ol>
                    <p class="c1 c8"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_6-0" start="2">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">La fecha y la hora en que se llevar&aacute; a cabo el o los
                            eventos, previamente establecido en el </span><span class="c2">REGISTRO DE EVENTO</span><span
                                class="c0">.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_6-0" start="3">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">Cuando aplique, temas para el gui&oacute;n de llamadas,
                            previamente establecido en el </span><span class="c2">REGISTRO DE EVENTO</span><span class="c0">.</span>
                        </li>
                    </ol>
                    <p class="c1 c8"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_6-0" start="4">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">&ldquo;Lista de invitaciones, registros y/o Invitados&rdquo;
                            con los siguientes datos por medio de un formato, provisto por </span><span
                                class="c2">CONFIRMARE</span><span class="c0">, en un archivo digital Excel con las siguientes
                                    informaciones:</span></li>
                    </ol>
                    <p class="c1"><span class="c0"></span></p>
                    <ul class="c3 lst-kix_list_1-0 start">
                        <li class="c6 c10 c11 li-bullet-0"><span class="c0">Nombre del titular de la invitaci&oacute;n junto con el
                            g&eacute;nero y t&iacute;tulo correspondiente</span></li>
                        <li class="c6 c10 c11 li-bullet-0"><span class="c0">Uno o dos tel&eacute;fonos de contacto con los siguientes
                            criterios:</span></li>
                    </ul>
                    <ul class="c3 lst-kix_list_9-0 start">
                        <li class="c6 c10 c18 li-bullet-0"><span class="c0">C&oacute;digo del pa&iacute;s</span></li>
                        <li class="c6 c10 c18 li-bullet-0"><span class="c0">N&uacute;mero de tel&eacute;fono celular</span></li>
                    </ul>
                    <ul class="c3 lst-kix_list_1-0">
                        <li class="c6 c10 c11 li-bullet-0"><span class="c0">Cantidad total de boletos o n&uacute;mero total de invitados
                            (personas)</span></li>
                        <li class="c6 c10 c11 li-bullet-0"><span class="c0">Invitado por qui&eacute;n</span></li>
                        <li class="c6 c10 c11 li-bullet-0"><span class="c0">Notas para invitados especiales</span></li>
                    </ul>
                    <p class="c1 c18"><span class="c0"></span></p>
                    <p class="c19 c17"><span class="c0"></span></p>
                    <p class="c6"><span class="c2">EL CLIENTE </span><span class="c4">deber&aacute; entregar la informaci&oacute;n
                        estipulada en esta Cl&aacute;usula a </span><span class="c2">CONFIRMARE</span><span class="c4">&nbsp;dentro
                            de las fechas previamente establecidas en el </span><span class="c2">CALENDARIO DE TRABAJO</span><span
                                class="c4">. La informaci&oacute;n proporcionada formar&aacute; parte del </span><span class="c2">REGISTRO
                                    DE EVENTO</span><span class="c0">. </span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">As&iacute; mismo, </span><span class="c2">CONFIRMARE </span><span
                        class="c4">dispondr&aacute; el estado de la lista de invitaciones, registros y/o invitados actualizados en
                        la periodicidad convenida en el </span><span class="c2">REGISTRO DE EVENTO</span><span class="c4">, cual
                        </span><span class="c2">EL CLIENTE </span><span class="c4">tendr&aacute; derecho de acceso a dicha
                            informaci&oacute;n, para consulta, mediante el uso de Mi Confirmare. Por lo que </span><span
                                class="c2">CONFIRMARE</span><span class="c4">&nbsp;proveer&aacute; a</span><span class="c2">&nbsp;EL CLIENTE
                        </span><span class="c0">el enlace para acceder a el detalle de su evento. </span></p>
                    <p class="c6"><span class="c4">En el caso de que </span><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;no
                        requiera la informaci&oacute;n v&iacute;a Mi Confirmare, se convendr&aacute;n las fechas en las que se le
                        presentar&aacute; a </span><span class="c2">EL CLIENTE </span><span class="c0">copias digitales y/o impresas
                            de las actualizaciones de la lista de invitaciones, registros y/o invitados.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Por &uacute;ltimo, en el caso de que se realice modificaciones a la &ldquo;Lista de
                        invitaciones, registros y/o Invitados&rdquo;, </span><span class="c2">EL CLIENTE</span><span
                            class="c4">&nbsp;deber&aacute; notificar a </span><span class="c2">CONFIRMARE</span><span
                                class="c4">&nbsp;por lo menos 5 (cinco) d&iacute;as naturales previos a la fecha de inicio del o los
                            servicios contratados detallado en el </span><span class="c2">CALENDARIO DE TRABAJO</span><span
                                class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">As&iacute; mismo, </span><span class="c2">EL CLIENTE</span><span
                        class="c4">&nbsp;est&aacute; expresamente enterado y de acuerdo en que la calidad y avance del o los
                        servicios prestados por </span><span class="c2">CONFIRMARE</span><span class="c4">&nbsp;depender&aacute; de
                            la calidad de informaci&oacute;n proporcionada y del cumplimiento en la fecha de entrega de la &ldquo;Lista
                            de invitaciones, registros y/o Invitados&rdquo; por</span><span class="c2">&nbsp;EL CLIENTE</span><span
                                class="c4">&nbsp;que est&aacute; establecida en el </span><span class="c2">CALENDARIO DE TRABAJO.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Por lo anterior, </span><span class="c2">EL CLIENTE </span><span class="c4">acepta
                        expresamente que en el caso de atraso o mora en la entrega de la &ldquo;Lista de invitaciones, registros y/o
                        invitados&rdquo; o la informaci&oacute;n que requiera </span><span class="c2">CONFIRMARE</span><span
                            class="c4">&nbsp;para el cumplimiento del o los servicios, seg&uacute;n estipulados en la </span><span
                                class="c2">COTIZACI&Oacute;N Y</span><span class="c4">&nbsp;</span><span class="c2">CALENDARIO DE
                                    TRABAJO</span><span class="c4">, las fechas ser&aacute;n ajustadas nuevamente. En caso de que </span><span
                                        class="c2">EL CLIENTE</span><span class="c4">&nbsp;reincida en retraso o mora, el o los servicios
                                            ser&aacute;n considerados como servicios express. Por lo que </span><span class="c2">CONFIRMARE,
                        </span><span class="c4">en cualquier de los dos casos mencionados</span><span class="c2">&nbsp;</span><span
                            class="c4">anteriormente,</span><span class="c2">&nbsp;</span><span class="c4">no estar&aacute; obligado a
                                garantizar al 100% el desarrollo y realizaci&oacute;n del o los servicios contratados por </span><span
                                    class="c2">EL CLIENTE.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">En el caso de que aplique el p&aacute;rrafo anterior, </span><span class="c2">EL
                        CLIENTE </span><span class="c4">deber&aacute; pagar adicionalmente a </span><span
                            class="c2">CONFIRMARE</span><span class="c0">&nbsp;el equivalente a 30% treinta por ciento del valor total
                                de la Contraprestaci&oacute;n m&aacute;s m&aacute;s el o los importes de Impuestos que apliquen (IVA y/o
                                ISR) establecida en la Cl&aacute;usula Quinta por concepto de atraso o mora.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />

                    <p class="c6"><span class="c2">QUINTA. - CONTRAPRESTACI&Oacute;N. - EL CLIENTE pagar&aacute;</span><span
                        class="c4">&nbsp;a CONFIRMARE la cantidad establecida en la </span><span
                            class="c2">COTIZACI&Oacute;N</span><span class="c4">, m&aacute;s el o los importes de Impuestos que apliquen
                                (IVA y/o ISR), por concepto de Contraprestaci&oacute;n por el o los Servicios prestados por </span><span
                                    class="c2">CONFIRMARE</span><span class="c0">, suma que ser&aacute; pagadera de las siguientes
                                        formas:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_4-0 start" start="1">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c0">50% (cincuenta porcientos) a la firma del Contrato, por
                            concepto de anticipo.</span></li>
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">50% (cincuenta porcientos) antes de los 10 (diez) d&iacute;as
                            naturales posterior a la fecha de inicio de o los Servicios descrita en el </span><span
                                class="c2">CALENDARIO DE TRABAJO</span><span class="c0">, por concepto de la parte restante. </span>
                        </li>
                    </ol>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c2">EL CLIENTE </span><span class="c4">realizar&aacute; el pago de la
                        Contraprestaci&oacute;n mediante dep&oacute;sito y/o transferencia bancaria a la cuenta que </span><span
                            class="c2">CONFIRMARE</span><span class="c4">&nbsp;le indique a </span><span class="c2">EL CLIENTE
                        </span><span class="c4">o con cheque bancario a favor de la persona que designe </span><span
                            class="c2">CONFIRMARE</span><span class="c4">,</span><span class="c2">&nbsp;</span><span class="c4">quien a
                                su vez expedir&aacute; la factura o el recibo correspondiente dentro de los 5 cinco d&iacute;as posteriores
                                a la fecha de pago.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c2">EL CLIENTE </span><span class="c4">acepta y se obliga a que en el caso de que exista
                        mora o retraso en el pago de alguna de las prestaciones econ&oacute;micas pactadas en esta Cl&aacute;usula,
                        deber&aacute; cubrir por concepto de Intereses Moratorios un porcentaje del 5% cinco por ciento mensual por
                        la cantidad total adeudada, hasta el momento en que se verifique dicho pago atrasado o vencido. Cabe aclarar
                        que cualquier cantidad recibida por </span><span class="c2">CONFIRMARE</span><span class="c0">&nbsp;se
                            destinar&aacute; en primer lugar a cubrir el concepto de Intereses Moratorios y el resto se aplicar&aacute;
                            al capital.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Bajo reserva de derechos, </span><span class="c2">CONFIRMARE</span><span
                        class="c4">&nbsp;podr&aacute; otorgar un per&iacute;odo de gracia para el cumplimiento de las obligaciones
                        de pago aqu&iacute; pactadas, s&oacute;lo en caso de que </span><span class="c2">EL CLIENTE</span><span
                            class="c0">&nbsp;le notifique las razones, por escrito, por las cuales se encuentra en retraso el
                            pago.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">Cabe mencionar que, en el caso de existir modificaci&oacute;n en los servicios,
                        nuevas campa&ntilde;as (mensajes), en el n&uacute;mero de &ldquo;invitaciones, registros y/o
                        invitados&rdquo;, realizar llamadas (nacionales o internacionales), adicionales fuera de la fecha
                        establecida, en el </span><span class="c2">CALENDARIO DE TRABAJO, </span><span class="c4">realizar
                            m&aacute;s de 3 (tres) intentos internacionales, reconfirmaciones o peticiones posteriores no establecidas
                            en la </span><span class="c2">COTIZACI&Oacute;N</span><span class="c4">&nbsp; por parte de </span><span
                                class="c2">EL CLIENTE</span><span class="c0">, los montos estipulados en esta Cl&aacute;usula podr&aacute;n
                                    ser ajustados en el Estado de Cuenta Final, aplic&aacute;ndose las mismas reglas de pago estipulas en esta
                                    Cl&aacute;usula.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">SEXTA. - OBLIGACIONES DE CONFIRMARE. - CONFIRMARE </span><span
                        class="c0">tendr&aacute; las siguientes obligaciones al proporcionar el o los servicios:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_10-0 start" start="1">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">CONFIRMARE </span><span class="c4">proporcionar&aacute; el o
                            los servicios con la m&aacute;s estricta confidencialidad con relaci&oacute;n a la informaci&oacute;n
                            provista por </span><span class="c2">EL CLIENTE </span><span class="c4">con conformidad al </span><span
                                class="c2">AVISO DE PRIVACIDAD</span><span class="c0">.</span></li>
                    </ol>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_10-0" start="2">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">CONFIRMARE </span><span class="c0">dispondr&aacute; con
                            suficiente personal capacitado para cumplir con el objeto de la contrataci&oacute;n durante la vigencia
                            del o los Servicios.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_10-0" start="3">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">CONFIRMARE </span><span class="c4">dispondr&aacute; de la
                            &ldquo;Lista de invitaciones, registros y/o invitados&rdquo;, actualizada constantemente, en tiempo y
                            forma convenida con </span><span class="c2">EL CLIENTE </span><span class="c4">de manera que se describe
                                en el </span><span class="c2">REGISTRO DE EVENTO</span><span class="c0">.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_10-0" start="4">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">CONFIRMARE</span><span class="c4">&nbsp;desarrollar&aacute;
                            las actividades objeto cumpliendo los est&aacute;ndares de satisfacci&oacute;n de </span><span
                                class="c2">EL CLIENTE</span><span class="c4">&nbsp;manifestado en las </span><span
                                    class="c2">POL&Iacute;TICAS DE TRABAJO</span><span class="c0">. Y,</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_10-0" start="5">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">CONFIRMARE</span><span class="c4">&nbsp;prestar&aacute; el o
                            los Servicios convenidos de acuerdo a los manifestados en la </span><span
                                class="c2">COTIZACI&Oacute;N.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">S&Eacute;PTIMA. - OBLIGACIONES DE EL CLIENTE. - EL CLIENTE</span><span
                        class="c0">&nbsp;tendr&aacute; las siguientes obligaciones al convenir la prestaci&oacute;n del o los
                        servicios o:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_11-0 start" start="1">
                        <li class="c6 c8 c10 li-bullet-0"><span class="c2">EL CLIENTE </span><span class="c4">deber&aacute; facilitar la
                            recolecci&oacute;n de informaci&oacute;n necesaria para el correcto desarrollo del o los servicios, de
                            acuerdo a las condiciones estipuladas en el </span><span class="c2">CALENDARIO DE TRABAJO</span><span
                                class="c4">&nbsp;y </span><span class="c2">POL&Iacute;TICAS DE TRABAJO</span><span class="c0">.</span>
                        </li>
                    </ol>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_11-0" start="2">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;deber&aacute;
                            realizar el pago de las Contraprestaciones, en las fechas y condiciones establecidas en el </span><span
                                class="c2">CALENDARIO DE TRABAJO</span><span class="c0">, en la cl&aacute;usula Quinta, as&iacute; como
                                    en el Estado de Cuenta Final.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_11-0" start="3">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;deber&aacute; cumplir
                            con los tiempos de entrega de la Informaci&oacute;n establecidos en el </span><span
                                class="c2">CALENDARIO DE TRABAJO</span><span class="c0">.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_11-0" start="4">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;, cuando aplique,
                            deber&aacute; tener entregadas a sus respectivos &ldquo;invitados&rdquo; todas las invitaciones
                            correspondientes al evento, previo al comienzo del o los Servicios de </span><span
                                class="c2">CONFIRMARE. </span><span class="c0">Y,</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_11-0" start="5">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;deber&aacute; siempre
                            procurar la calidad de la informaci&oacute;n contenida en la &ldquo;Lista de invitaciones, registros y/o
                            invitados&rdquo; que forma parte del </span><span class="c2">REGISTRO DE EVENTO</span><span
                                class="c0">.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <p class="c5"><span class="c0"></span></p>
                    <br />

                    <p class="c6"><span class="c2">OCTAVA. - DURACI&Oacute;N DEL CONTRATO Y DE LOS SERVICIOS. - </span><span
                        class="c4">Las Partes convienen en que la duraci&oacute;n del o los servicios tendr&aacute;n vigencia a
                        partir de la firma del Contrato hasta la fecha de terminaci&oacute;n estipulada en el </span><span
                            class="c2">CALENDARIO DE TRABAJO</span><span class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">NOVENA. - CAUSALES DE TERMINACI&Oacute;N ANTICIPADA. - </span><span
                        class="c0">Ser&aacute; causa de terminaci&oacute;n anticipada del Contrato:</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_12-0 start" start="1">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c0">Por convenio escrito en que las Partes acuerden que el
                            Contrato se da por terminado, debi&eacute;ndose notificar entre ambas Partes en un t&eacute;rmino no
                            menor de 30 (treinta) d&iacute;as naturales de anticipaci&oacute;n a la fecha de terminaci&oacute;n de
                            este Contrato.</span></li>
                    </ol>
                    <p class="c1 c13"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_12-0" start="2">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c0">El incumplimiento por las Partes de cualquiera de las
                            cl&aacute;usulas del Contrato, siempre y cuando dicho incumplimiento no sea subsanado en un plazo de 5
                            (cinco) d&iacute;as h&aacute;biles.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_12-0" start="3">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">Si </span><span class="c2">CONFIRMARE</span><span
                            class="c4">&nbsp;utiliza la informaci&oacute;n provista por </span><span class="c2">EL
                                CLIENTE</span><span class="c0">&nbsp;para uso distinto al se&ntilde;alado en el Contrato.</span></li>
                    </ol>
                    <p class="c5"><span class="c0"></span></p>
                    <ol class="c3 lst-kix_list_12-0" start="4">
                        <li class="c6 c10 c8 li-bullet-0"><span class="c4">Si </span><span class="c2">EL CLIENTE</span><span
                            class="c4">, luego de la fecha de pagos de la contraprestaci&oacute;n, no ha realizado alg&uacute;n
                            pago, y una vez que </span><span class="c2">CONFIRMARE</span><span class="c4">&nbsp;le hubiese
                                notificado dicha situaci&oacute;n a </span><span class="c2">EL CLIENTE</span><span class="c0">, y
                                    &eacute;ste no lo resuelve en un tiempo m&aacute;ximo de 2 (dos) d&iacute;as h&aacute;biles.</span></li>
                    </ol>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c4">En el caso de que se d&eacute; la terminaci&oacute;n anticipada del Contrato,
                        as&iacute; como la prestaci&oacute;n de o los servicios de </span><span class="c2">CONFIRMARE</span><span
                            class="c4">, </span><span class="c2">EL CLIENTE</span><span class="c4">&nbsp;por medio del Contrato acuerda
                                en que por ning&uacute;n motivo ser&aacute; devuelto o reembolsado, sea de forma total o parcial el pago de
                                la Contraprestaci&oacute;n que ya se ha realizado a favor de </span><span class="c2">CONFIRMARE</span><span
                                    class="c0">.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c6"><span class="c0">En el caso de la terminaci&oacute;n anticipada del Contrato las Partes deber&aacute;n
                        tomar las medidas necesarias para evitar perjuicios tanto as&iacute; mismos como a terceros.</span></p>
                    <p class="c1"><span class="c0 c14"></span></p>
                    <p class="c6"><span class="c0">Cabe se&ntilde;alar que, sea por terminaci&oacute;n anticipada o al t&eacute;rmino de
                        la vigencia del Contrato, cualquier tema y/o asuntos que se ventilen como pendientes, continuar&aacute;n
                        siendo tramitados por las Partes en lo que corresponda, de acuerdo a los compromisos que se ha adquirido en
                        el Contrato, hasta su total terminaci&oacute;n.</span></p>
                    <p class="c1"><span class="c0 c14"></span></p>
                    <p class="c1"><span class="c0 c14"></span></p>
                    <br />
                    <p class="c6"><span class="c2">D&Eacute;CIMA. - VALIDEZ. - </span><span class="c0">Las Partes declaran que en la
                        celebraci&oacute;n del Contrato no existe dolo, lesi&oacute;n, mala fe, violencia ni cualquier otro vicio
                        del consentimiento que pudiera invalidar al presente instrumento.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">D&Eacute;CIMA PRIMERA. - INDEPENDENCIA DEL CLAUSULADO. - </span><span class="c0">En
                        el caso de que una o m&aacute;s de las estipulaciones contenidas se llegare a declarar como inv&aacute;lido
                        por mandamiento de autoridad judicial, las dem&aacute;s Cl&aacute;usulas aqu&iacute; contenidas,
                        continuar&aacute;n con plena validez y efecto jur&iacute;dico entre las Partes. </span></p>
                    <p class="c1"><span class="c0 c14"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">D&Eacute;CIMA SEGUNDA. - ENCABEZADOS. - </span><span class="c0">Las Partes han
                        convenido en que los encabezados que aparecen al inicio de cada una de las Cl&aacute;usulas que los
                        t&eacute;rminos y condiciones se han insertado &uacute;nica y exclusivamente para facilitar su lectura y
                        manejo, motivo por el cual, no se considera en ning&uacute;n caso que definan o limiten las obligaciones y
                        derechos que se deriven para cada una de las Partes. </span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">D&Eacute;CIMA TERCERA. - DEFINITIVIDAD. - </span><span class="c0">El Contrato
                        mediante el cual se representa la manifestaci&oacute;n de la voluntad y tiene car&aacute;cter de definitivo
                        entre las Partes relaci&oacute;n con su objeto, en virtud de lo cual, a partir de este momento deja sin
                        efecto cualquier otro acuerdo oral, escrito, t&aacute;cito o expreso, que en relaci&oacute;n con el objeto
                        del Contrato pudiera existir entre las Partes, debiendo subsistir &uacute;nica y exclusivamente la
                        relaci&oacute;n jur&iacute;dica derivada de este instrumento.</span></p>
                    <p class="c1 c13"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <br />
                    <p class="c6"><span class="c2">D&Eacute;CIMA CUARTA. - LEY APLICABLE Y COMPETENCIA. -</span><span
                        class="c0">&nbsp;Para la interpretaci&oacute;n y cumplimiento del los T&eacute;rminos y Condiciones
                        as&iacute; como el Contrato, las Partes se someten a la competencia de los tribunales del primer partido
                        Judicial del Distrito Federal y a las disposiciones establecidas en el C&oacute;digo Civil del Distrito
                        Federal vigente, por lo que renuncia expresamente al fuero que por raz&oacute;n de su domicilio presente o
                        futuro pudiera corresponderles.</span></p>
                    <p class="c1 c13"><span class="c0"></span></p>
                    <p class="c6"><span class="c0">LE&Iacute;DO que fue los T&eacute;rminos y Condiciones por las Partes que en
                        &eacute;l intervienen y enterados de su contenido, valor, alcance y consecuencias legales, se manifestaron
                        conforme con las condiciones anteriores y su clausulado y ratific&aacute;ndolo.</span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <p class="c1"><span class="c0"></span></p>
                    <div>
                        <p class="c16 c17"><span class="c7"></span></p>
                        <p class="c16 c17"><span class="c9"></span></p>
                        <p class="c16"><span class="c25">Copyright &copy; 2022 Confirmare</span><span class="c20">MR</span><span
                            class="c25">. Todos los derechos reservados.</span></p>
                        <p class="c15"><span class="c7"></span></p>
                    </div>
                </body>

            </section>
            <Footer/>
        </>

    );

}

export default TermsAdnConditions;