import React from 'react';
import Footer from '../components/footer'



const PrivacyPolicy = () => {
    return (
        <>
            <section style={{ marginTop: "10rem", marginBottom: "3rem", padding: "2rem" }}>
                <h1>A V I S O   D E   P R I V A C I D A D</h1>
                <br />
                <p>En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares, la marca registrada "CONFIRMARE", así como los titulares de la marca registrada JULIETA CARRILLO ORZCO y/o CECILIA ISABEL AGUAYO IBARRA, así como todos los prestadores de servicios y partners relacionados con la marca en mención (en lo sucesivo de manera conjunta o en lo individual "CONFIRMARE") hacen de su conocimiento la política de privacidad y manejo de datos personales, en la que en todo momento buscarán que el tratamiento de los mismos sea legítimo, controlado e informado, a efecto de asegurar la privacidad, confidencialidad, integridad y el derecho a la autodeterminación informativa de sus datos.</p>
                <br />
                <p>CONFIRMARE sólo obtiene los datos personales de sus titulares, ya sea porque se tiene una relación de prestación de servicios profesionales con dicho titular, de manera directa y personal, o bien, a través de los medios electrónicos, ópticos, sonoros, visuales o por cualquier otra tecnología u otras fuentes que estén permitidas por la ley, con la finalidad de prestar los servicios a los clientes que han contratado a CONFIRMARE de conformidad con las leyes y disposiciones aplicables y con el único propósito de estar en posibilidades de realizar el objeto de los servicios prestados por CONFIRMARE, que en su caso y por acuerdo mutuo se pretenda formalizar, para mantener y custodiar el expediente e información respectiva.</p>
                <br />
                <p>Asimismo, CONFIRMARE podrá usar la información de los titulares de los datos personales para contactarles provista por los clientes, entender mejor sus necesidades, recabar información y cualquier acto tendiente afín con las necesidades de prestar los servicios a los clientes de CONFIRMARE.</p>
                <br />
                <p>En caso que los titulares de la información deseen ejercer sus derechos de acceso, rectificación, cancelación u oposición, estos los podrán ejercer en todo momento, de conformidad con el mismo procedimiento para girar cualquier otra instrucción derivada del contrato de prestación de servicios de CONFIRMARE con dicho titular, pudiendo realizar esto a través del personal de CONFIRMARE por los canales aplicables. Si requieren alguna ayuda o asesoría podrán comunicarse vía telefónica de lunes a viernes de 9hrs y hasta las 19hrs de lunes a viernes. De las 10hrs a las 14hrs los sábados., a los teléfonos +52 (33) 9688 0318 para la Ciudad de Guadalajara, Jalisco y para el interior de la República Mexicana al 800 999 0468 o directamente al correo electrónico <a href="mailto:admin@confirmare.com.mx">admin@confirmare.com.mx</a>.</p>
                <br />
                <p>CONFIRMARE se compromete a que los datos personales serán tratados bajo las más estrictas medidas de seguridad que aseguren su confidencialidad.</p>
                <br />
                <p>CONFIRMARE se compromete a que los datos personales obtenidos mediante los clientes no serán transferidos a terceras partes, salvo en los casos de que se han contratado a terceras partes para que realicen en su nombre ciertas tareas relacionadas con la prestación de servicios de CONFIRMARE. En cualquiera de los casos CONFIRMARE seleccionará proveedores fiables que se comprometan, mediante un contrato u otros medios legalmente vinculantes y permisibles, a instaurar las medidas de seguridad necesarias para garantizar un nivel de protección adecuado.</p>
                <br />
                <p>CONFIRMARE exigirá a los proveedores externos que cumplan con estas normas o que garanticen los mismos niveles de protección implementada durante el tratamiento de los datos.</p>
                <br />

                <p>CONFIRMARE se reserva el derecho de modificar o actualizar el presente aviso de privacidad en cualquier momento, para la atención, adecuación y cumplimiento de las modificaciones legales, que, en su caso, sean aplicables, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos, y lo mantendrá siempre a disposición por este conducto para su consulta.</p>
            </section>
            <Footer />
        </>


    );

}

export default PrivacyPolicy;