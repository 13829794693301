
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }


#root {
  white-space: pre-line;
}
  
  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  p::selection {
    background: black;
    color: white;
  }

  /* Firefox */
  p::-moz-selection {
    background: black;
      color: white;
  }

  span::selection {
    background: black;
    color: white;
  }

  /* Firefox */
  span::-moz-selection {
    background: black;
      color: white;
  }
  
`;

export default GlobalStyles;