import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import theme from './styles/themes';
import GlobalStyles from './styles/global-styles';
import { I18nextProvider } from 'react-i18next';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
  <ThemeProvider theme={theme}>
  <I18nextProvider i18n={i18n}>

      <App />
      </I18nextProvider>

      <GlobalStyles />
  </ThemeProvider>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
