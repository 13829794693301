import React from 'react';
import ExpInfo from '../components/exp'
import Footer from '../components/footer'
import styled from 'styled-components';
import bg from '../assets/conf-mi-conf-bg.png'
import labelIcon from '../assets/mi_conf_label_icon.png'
import icon1 from '../assets/mi_conf_icon_1.png'
import icon2 from '../assets/mi_conf_icon_2.png'
import icon3 from '../assets/mi_conf_icon_3.png'
import icon4 from '../assets/mi_conf_icon_4.png'
import icon5 from '../assets/mi_conf_icon_5.png'
import icon6 from '../assets/mi_conf_icon_6.png'
import icon7 from '../assets/mi_conf_icon_7.png'
import icon8 from '../assets/mi_conf_icon_8.png'


import { Grid, Typography, Input, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';


const MiConfirmare = () => {
    const { t } = useTranslation();

    return (
        <div style={{ marginTop: "10rem" }}>

            <Section >
                <GridWrapper container style={{ marginTop: "3rem" }} >
                    <LoginLabelContainer item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Typography variant="h3" fontFamily="Countryside">
                            {t('miConfirmareTitle')}
                        </Typography >
                        <IconService src={labelIcon} />

                        <Typography variant="p" marginX={10}>
                            {t('miConfirmareDescription')}
                        </Typography >
                    </LoginLabelContainer>
                    <LoginContainer item xs={12} md={6}>
                        <Box width={"50%"}
                            sx={{
                                py: 3,
                                display: 'grid',
                                gap: 4,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Typography variant="p" align='center' lineHeight={2.5}>

                                {t('loginLabel')}

                            </Typography >

                        </Box>

                    </LoginContainer>
                </GridWrapper>
            </Section>
            <Section >
                <Grid container spacing={2} columns={16} padding={5} marginBottom={10}>
                    <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon1} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('consultaConfirmaciones')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon2} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('statusInvitados')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon3} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('detalleIntentos')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon4} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('diferenciasBoletos')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon5} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('filtros')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon6} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('buscador')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon7} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('comentarios')}
                        </Typography >

                    </CustomGridItem>   <CustomGridItem item xs={8} md={2} spacing={4}>
                        <IconWrapper  >
                            <IconService src={icon8} />
                        </IconWrapper >
                        <Typography  style={{fontFamily: 'Montserrat-medium'}}>
                            {t('reportes')}
                        </Typography >

                    </CustomGridItem>

                </Grid>

            </Section>

            <Footer />

        </div>);


}

export default MiConfirmare;


const Section = styled.section`
margin-top: 3rem;
  padding: 0px;
  
  @media (min-width: 768px) {
        padding: 0 4rem;
      }

  
`;


const LoginLabelContainer = styled(Grid)`
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    height: 400px;
    background-color: #D8ECE6;
    padding: 3rem;

  p,h4{
    font-size: 12px;
  }
`;

const LoginContainer = styled(Grid)`

    height: 400px;
    background-image: url(${bg});
    background-repeat: repeat;
    background-size: contain;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;

  p,h4{
    color:#DF7C7B;
    font-size: 12px;
  }
`;


const GridWrapper = styled(Grid)`
`;

const CustomGridItem = styled(Grid)`
text-align: center;
  p,h4{
    color:#DF7C7B;
    font-size: 12px;
  }
`;



const ImgServ = styled.img`
  width: 100%;
  max-width: 500px;
  
  
  
`;


const ServiceDescContainer = styled.div`
  padding: 0.5rem;
  background-color: #D8ECE6;
  width: 80%;
  font-size: 1rem;
  margin:auto;
  text-align: center;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;


const IconService = styled.img`
  margin: 3rem;
`;

const IconWrapper = styled.div`
    width: 100px;
    height: 100px;
  display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
`;


const LoginButton = styled.button`
width: 140px;
padding: 0.8rem;
    border-radius: 0px  20px 20px 0px;
    background-color: #4d4d4d;
    border-radius: none;
    outline: none;
    color: #fff;
`
