import React from 'react';
import { Typography, Grid } from '@mui/material';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';



const ExperieceInfo = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <ExpContainer style={{textAlign: props.textDirection, alignItems: props.textDirection == "right" ? "end": "left" }}>
            <ImgExp src={props.icon} />
            <Title variant="h4" >
                {props.title}
            </Title>
            <Description variant="p" dangerouslySetInnerHTML={{ __html: props.description }}>
            </Description>
        </ExpContainer>
    );
};

export default ExperieceInfo;



const ExpContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    /* align-items: end; */

`;


const ImgExp = styled.img`
  width: 50px;
  margin-bottom:1rem !important;
`;


const Title = styled(Typography)`
  color: #DF7C7B;
  font-family: 'Countryside' !important;
  margin-bottom:1rem !important;

`;

const Description = styled(Typography)`
`;