import React from 'react';
import { Typography, Grid } from '@mui/material';
import Divider from '../components/divider'
import ExpInfo from '../components/exp'
import Footer from '../components/footer'
import styled from 'styled-components';
import heroImg from '../assets/confirmare_img_1.png'
import expImg1 from '../assets/conf_exp_img1.jpg'
import expImg2 from '../assets/conf_exp_img2.jpg'
import expImg3 from '../assets/conf_exp_img3.jpg'
import expImg4 from '../assets/conf_exp_img4.jpg'
import expIcon1 from '../assets/conf_exp_icon_1.png'
import expIcon2 from '../assets/conf_exp_icon_2.png'
import expIcon3 from '../assets/conf_exp_icon_3.png'
import expIcon4 from '../assets/conf_exp_icon_4.png'
import expHistHeadImg from '../assets/conf_hist_head_img.jpg'
import expHistIcon from '../assets/conf_hist_icon.png'
import expHistImg1 from '../assets/conf_hist_img_1.png'
import expHistImg2 from '../assets/conf_hist_img_2.png'
import expHistImg3 from '../assets/conf_hist_img_3.png'
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';


const Experience = () => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "5rem" }}>
      <Section style={{
        backgroundImage: `url(${heroImg})`,
        height: "60vh",

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",

        justifyContent: "center",
        alignItems: "center"
      }}>
        <HeadTitleContainer sx={{ width: '100%' }} md={{ width: '50%' }}>
          {t('experienceTitle')}
        </HeadTitleContainer>

      </Section>
      <Section >
        <Divider text={t('dividerExperience')} />
        <GridWrapper container spacing={8}>
          <Grid item xs={12} md={6} style={{ textAlign: "right", display: "flex", justifyContent: "center" }} order={{ xs: 1 ,md: 1}}>
            <ImgExp src={expImg1} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2 ,md: 2}}>
            <ExpInfo textDirection="left" icon={expIcon1} title={t('ahorraTiempoTitle')} description={t('ahorraTiempoDescription').replace(/Event Planner/g, '<span style="font-style: italic;">Event Planner</span>')} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 4 ,md: 3}}>
            <ExpInfo textDirection="right" icon={expIcon2} title={t('ahorraDineroTitle')} description={t('ahorraDineroDescription')} />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right", display: "flex", justifyContent: "center" }} order={{ xs: 3 ,md: 4}}>
            <ImgExp src={expImg2} />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right", display: "flex", justifyContent: "center" }} order={{ xs: 5 ,md: 5}}>
            <ImgExp src={expImg3} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 6 ,md: 6}}>
            <ExpInfo textDirection="left" icon={expIcon3} title={t('tomaDecisionesTitle')} description={t('tomaDecisionesDescription').replace(/Event Planner/g, '<span style="font-style: italic;">Event Planner</span>')} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 8 ,md: 7}}>
            <ExpInfo textDirection="right" icon={expIcon4} title={t('despiertaAnfitrionTitle')} description={t('despiertaAnfitrionDescription')} />
          </Grid>
          <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center" }} order={{ xs: 7 ,md: 8}}>
            <ImgExp src={expImg4} />
          </Grid>
        </GridWrapper>

      </Section>

      <Section >
        <GridWrapper container >
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            <ImgHistory src={expHistHeadImg} />
          </Grid>
          <Grid item xs={12} md={6}>
            <HeadHistoryContainer>
              <Title  fontSize={{ xs: '2rem' ,md: '3rem'}}>
                {t('historiasConfirmareTitle')}
              </Title>
              <HisyIcon src={expHistIcon} />

              <Description variant="p" style={{fontSize: '0.8rem'}}>
                {t('historiasConfirmareDescription')}
              </Description>
            </HeadHistoryContainer>
          </Grid>

        </GridWrapper>

        <GridWrapper container spacing={3} marginBottom={10}>
          <GridHistory item xs={12} md={4} >
            <ImgHistory src={expHistImg1} />
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial1')}
            </Typography >
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial1Desc')}
            </Typography >
            <Typography variant="h6" align="right" style={{fontFamily: 'Countryside'}}>
              {t('testimonial1Author')}
            </Typography >
          </GridHistory>
          <GridHistory item xs={12} md={4}>
            <ImgHistory src={expHistImg2} />
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial2')}
            </Typography >
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial2Desc')}
            </Typography >
            <Typography variant="h6" align="right" style={{fontFamily: 'Countryside'}}>
              {t('testimonial2Author')}
            </Typography >
          </GridHistory>
          <GridHistory item xs={12} md={4}>
            <ImgHistory src={expHistImg3} />
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial3')}
            </Typography >
            <Typography  align="center" style={{fontFamily: 'Montserrat-light'}}>
              {t('testimonial3Desc')}
            </Typography >
            <Typography variant="h6" align="right" style={{fontFamily: 'Countryside'}} >
              {t('testimonial3Author')}
            </Typography >
          </GridHistory>


        </GridWrapper>

      </Section>
      <Footer />

    </div>
  );
};

export default Experience;

const Section = styled.section`

  padding: 0px;
  
  @media (min-width: 768px) {
        padding: 0 4rem;
      }

  
`;

const HeadTitleContainer = styled(Container)`
   font-family: 'TrajanPro' !important;
  padding: 4rem;
  background-color: #ffffffc7;
  font-size: 1.5rem;
  text-align: center;
  max-width: 500px !important;
  
  
  
`;

const GridWrapper = styled(Grid)`
  padding: 2rem;
`;

const GridHistory = styled(Grid)`
  p,h4{
    padding:2rem;
  }
`;


const ImgExp = styled.img`
  width: 100%;
  margin: auto;
  display: block;

  
`;


const HeadHistoryContainer = styled.div`
  padding: 4rem;
  background-color: #D8ECE6;
  height: 100%;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const ImgHistory = styled.img`
  width: 100%;
  display:  block;
`;


const Title = styled(Typography)`
    font-family: 'Countryside' !important;

`;

const Description = styled(Typography)`
`;

const HisyIcon = styled.img`
  width: 50px;
 
  
  
  
`;