import React from 'react';
import styled from 'styled-components';




const Divider = (props) => {
    return (
        <DividerContainer>
            <DividerInline></DividerInline>
            <DividerText>{props.text}</DividerText>
            <DividerInline></DividerInline>
        </DividerContainer>
    );
};

export default Divider;

const DividerInline = styled.div`
    flex-grow: 1;
    height: 2px;
    background-color: #90C1BD;
`;

const DividerContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: 3rem 0;

`;

const DividerText = styled.div`
   margin: 0 1rem;
   color: #90C1BD;
`;