import React from 'react';
import ExpInfo from '../components/exp'
import Footer from '../components/footer'
import styled from 'styled-components';
import contactImg from '../assets/conf_contact_img.png'
import { Grid, Typography, Input, Box, TextField, InputAdornment, FormControl, InputLabel, FilledInput } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ marginTop: "5rem" }}>
      <Section style={{
        backgroundImage: `url(${contactImg})`,
        marginTop: "8rem",
        height: "max-content",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        marginBottom: "4rem",

        justifyContent: "center",
        alignItems: "center"
      }}>
        <HeadTitleContainer>
          <Typography style={{fontFamily: 'Montserrat-light', marginBottom: '1rem'}}>
            {t('contactTitle')}
          </Typography >
          <form action="https://getform.io/f/fbedc755-ac66-4168-beef-e97f81364067"
            method="POST">

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelNombre')}
                    id="name"
                    name='name'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelApellido')}
                    id="lastName"
                    name='lastName'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelCorreo')}
                    id="email"
                    name='email'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelCiudad')}
                    id="city"
                    name='city'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelEstado')}
                    id="state"
                    name='state'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelTelefono')}
                    id="phone"
                    name='phone'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelFechaEvento')}
                    id="eventDate"
                    name='eventDate'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'
                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >
                <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
                  <TextField
                    label={t('labelCantidadInvitados')}
                    id="guests"
                    name='guests'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        color: '#000'

                      }
                    }}
                    required={true}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container marginTop={5}
              direction="row"
              justifyContent="space-between">
              <p style={{ fontSize: '1rem',fontFamily: 'Montserrat-light'}}>
                {t('labelRequerido')}

              </p>
              <LoginButton type='submit' style={{fontFamily: 'Montserrat-medium'}}>
                {t('buttonEnviar')}
              </LoginButton>
            </Grid>
          </form>

        </HeadTitleContainer>

      </Section>
      <Footer />


    </div>
  );
};

export default Contact;

const Section = styled.section`

  padding: 0px;
  
  @media (min-width: 768px) {
        padding: 2rem 4rem;
      }

  
`;

const HeadTitleContainer = styled.div`
  padding: 4rem;
  background-color: #ffffffc7;
  font-size: 1.5rem;
  text-align: center;
  
  
  
`;

const LoginButton = styled.button`
width: 140px;
padding: 0.5rem;
    border-radius: 0px  20px 20px 0px;
    background-color: #4d4d4d;
    border-radius: none;
    outline: none;
    color: #fff;
`