import React from 'react';
import { Typography } from '@mui/material';
import background from "../assets/confirmare-background.png"; // with import
import logo from "../assets/confirmare-logo.png"; // with import
import facebook from "../assets/confirmare-facebook.png"; // with import
import ig from "../assets/conjfirmare-ig.png"; // with import
import whatsapp from "../assets/confirmare-whatsapp.png"; // with import
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';


const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        height: "100vh",
        backgroundRepeat: "repeat",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
          width: '50%',
          maxWidth: '400px',
          height: "60%"
        }}
        sm={{ width: '100%' }}
      >
        <img src={logo} style={{ width: '100%' }} />
      </Container>
      <Container md={{ padding: "20px 100px" }} sx={{ padding: '0px' }}>
        <p style={{ textAlign: "center" }}>
          {t('homeTitle1')}
        </p>
        <p style={{ textAlign: "center" }}>
          {t('homeTitle2')}
        </p>
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "auto"
        }}
      >
        <div>


          <div
            style={{
              marginTop: "4rem",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <a href='https://www.facebook.com/confirmare' target="_blank">
              <img
                src={facebook}
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "15px"
                }}
              /></a>

            <a href='https://wa.me/+523396880318' target="_blank">
              <img
                src={whatsapp}
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "15px"
                }}
              />
            </a>
            <a href='https://www.instagram.com/confirmare.mx/' target="_blank">
              <img
                src={ig}
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "15px"
                }}
              />
            </a>
          </div>
          <p
            style={{ textAlign: "center", fontSize: "12px", fontWeight: "600", marginBottom: "1rem " }}
          >
            {t('homeSubtitle')}

          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
